body {
  height: 100%;
  background: #e7e7e9 linear-gradient(180deg, rgba(0, 0, 0, .04), transparent 10%);
}

#root {
  height: 100%;
}

.App {
  padding: 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
}
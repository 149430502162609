/* stylelint-disable */

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
body {
  font-feature-settings: 'kern';
  min-height: 100%;
  position: relative;
}
*,
*::before,
*::after {
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
}
main {
  display: block;
}
hr {
  border-top-width: 1px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  font-size: var(--font-regular-size);
}
a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bold;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  appearance: none !important;
}
input[type='number'] {
  appearance: textfield;
}
[type='search'] {
  appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  appearance: none !important;
}
::-webkit-file-upload-button {
  appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
button {
  background-color: transparent;
  padding: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
ol,
ul {
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
button,
[role='button'] {
  cursor: pointer;
}
button::-moz-focus-inner {
  border: 0 !important;
}
table {
  border-collapse: collapse;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  line-height: inherit;
  padding: 0;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}
img,
video {
  height: auto;
  max-width: 100%;
}
[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  box-shadow: none;
  outline: none;
}
select::-ms-expand {
  display: none;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

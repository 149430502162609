.wrapper {
  position: relative;
  min-height: 640px;

  overflow: hidden;
  display: flex;
  flex-grow: 1;

  background: var(--ci-surface-elevation-1);
  border-radius: 0.75rem;
  box-shadow: 0px 0px 2px rgba(22, 22, 23, 0.25),
    0px 4px 6px -2px rgba(22, 22, 23, 0.12),
    0px 2px 2.5px -2px rgba(22, 22, 23, 0.12),
    0px 1px 1.75px -2px rgba(22, 22, 23, 0.12);
}

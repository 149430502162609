.modal {
  position: absolute;
  max-width: min(1264px, 80vw);
  max-height: 80vh;
  inset: 0;
  margin: auto;
  overflow: hidden;
  background: #d6dbe1;
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgba(22, 22, 23, 0.25),
    0px 12px 18px -2px rgba(22, 22, 23, 0.12),
    0px 6px 7.5px -2px rgba(22, 22, 23, 0.12),
    0px 3px 5.25px -2px rgba(22, 22, 23, 0.12);
}

.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(123, 129, 135, 0.5);
}

.cesdkContainer {
  width: 100%;
  height: 100%;
}
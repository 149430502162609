/* IMG.LY CI v1.1 CSS Variables */
:root {
  /* Colors */
  /* ci Interactive */
  --ci-interactive-default: rgba(22, 22, 23, 0.08);

  /* CI Accent */
  --ci-accent-primary: #471aff;
  --ci-accent-primary-alternative: #00ffb7;
  --ci-accent-ce-sdk: #ad00ff;
  --ci-accent-ve-sdk: #f20c5f;
  --ci-accent-pe-sdk: #ff5c00;
  --ci-accent-gradient: linear-gradient(
    90deg,
    #471aff 0%,
    #ad00ff 44.93%,
    #f20c5f 71.27%,
    #ff5c00 99.15%
  );

  /* CI Surface */
  --ci-surface-elevation-1: #f4f4f4;

  /* CI Foreground */
  --ci-foreground-black-default: #161617;
  --ci-foreground-black-light: rgba(22, 22, 23, 0.65);
  --ci-foreground-black-lighter: rgba(22, 22, 23, 0.25);
  --ci-foreground-white-default: #ffffff;
  --ci-foreground-white-light: rgba(255, 255, 255, 0.65);
  --ci-foreground-white-lighter: rgba(255, 255, 255, 0.25);

  /* CI Border */
  --ci-border-primary: rgba(71, 26, 255, 0.3);
  --ci-border-black: rgba(22, 22, 23, 0.3);
  --ci-border-black-light: rgba(22, 22, 23, 0.08);
  --ci-border-white: rgba(255, 255, 255, 0.3);
}

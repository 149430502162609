/* BUTTON SMALL */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem 0.75rem 0.3125rem 1rem;
  border-radius: 8px;
  font-family: var(--font-family-ibm-sans);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 1.75rem;
  text-align: center;
  gap: 0.375rem;
}

.button--small {
  padding: 0.3125rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  gap: 0;
}

.button--small>span {
  display: flex;
  padding: 0rem 0.25rem;
}

.button.button--small>svg {
  width: 1rem;
  height: 1rem;
}

.button>svg {
  width: 1.5rem;
  height: 1.5rem;
}

.button--primary {
  color: var(--text-light-high-emphasis);
  border: 1px solid var(--ci-border-primary, rgba(71, 26, 255, 0.30));
  background: var(--ci-button-primary, linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.00) 100%), #471AFF);
}

.button--primary:hover:not(:disabled),
.button--primary:focus:not(:disabled) {
  border-radius: 0.5rem;
  border: 1px solid var(--ci-border-black, rgba(22, 22, 23, 0.30));
  background: var(--ci-button-primary-hover, linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0.00) 100%), #471AFF);
}

.button--primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button--primary:focus-visible {
  outline: 3px solid var(--primary-color);
}

.button--secondary {
  border: 1px solid var(--ci-border-black-light, rgba(22, 22, 23, 0.16));
  background: var(--ci-button-secondary-default, linear-gradient(180deg, rgba(255, 255, 255, 0.30) 0%, rgba(219, 222, 224, 0.30) 100%), rgba(255, 255, 255, 0.60));
}

.button--secondary:hover,
.button--secondary:focus {
  border: 1px solid var(--ci-border-black-light, rgba(22, 22, 23, 0.16));
  background: var(--ci-button-secondary-hover, linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(219, 222, 224, 0.60) 100%), rgba(219, 222, 224, 0.60));
}

/* secondary plain */
.button--secondary-plain {
  background-color: transparent;
}

.button--secondary-plain:hover,
.button--secondary-plain:focus {
  border-radius: 0.5rem;
  background: var(--ci-button-tertiary-hover, linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.00) 100%), rgba(22, 22, 23, 0.12));
}